.top-bar {
    height: 9vh;
    background-color: rgba(0, 0, 0, 0);
    padding-left: 30px;
    padding-right: 30px;
    position: fixed;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
}

.top-bar .logo-link {
    height: 100%;
    z-index: 110;
}

.top-bar .logo-link .logo {
    height: 100%;
    padding: 5px 0;
}


.header-layout {
    height: 9vh;
    background-color: transparent !important;
    box-shadow: none !important;
    border: 0 !important;
    padding: 0 30px;
}

.header-layout .item {
    text-transform: uppercase !important;
    /* color: var(--secondary) !important; */
    height: 100%;
    color: var(--primary);
    text-transform: uppercase;
    font-family: Montserrat, sans-serif!important;
    font-size: 14px;
    font-weight: 500!important;
    line-height: 18px;
    vertical-align: top!important;
    color: #222;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    padding: 20px!important;
    text-decoration: none;
    display: inline-block;
    position: relative;
    align-items: flex-start!important;
}

.header-layout .item::before {
    content: '';
    background-color: transparent !important;
}

.header-layout .right {
    align-items: center !important;
}


.header-layout .item.active {
    background-color: transparent !important;
    border-bottom: 3px solid var(--primary);
    color: #0082f3 !important;
    height: 94%;
    font-weight: 600!important;
}

.header-layout .right.menu .item:hover {
    font-weight: 600;
    color: #ec6429 !important;
}

.header-layout__image-link:link,
.header-layout__image-link:visited,
.header-layout__image-link {
    cursor: pointer;
    border-left: 0 !important;
    padding-left: 0 !important;
    height: 100%;
    object-fit: cover
}

.header-layout__image-link:hover,
.header-layout__image-link:active {
    background-color: transparent !important;
}


.header .nav-bar-open {
    display: flex !important;
}

.header .nav-bar-hide {
    display: flex !important;
}

/* 0   - 600px:    Phone
  600 - 900px:    Tablet portrait
  900 - 1200px:   Tablet landscape
  [1200 - 1800px] is where our normal styles apply
  1800px +    :   Big Desktop */

@media (max-width: 900px) {
    .mobile-bar {
        display: flex;
        z-index: 110;
    }    

    .nav-bar-hide {
        display: none !important;
    }

    .top-bar .ui.stackable.menu .right.menu {
        background-color: #ffffff;
        flex-direction: column;
        position: absolute;
        top: 9vh;
        left: 0;
        width: 100vw;
        transition: all 1s ease-in-out;
        overflow: hidden;
    }

    .header-layout .item {
        height: 25%!important;
        box-sizing: border-box;
    }
}

@media (min-width: 901px) {
    .mobile-bar {
        display: none;
    }
}