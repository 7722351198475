:root {
  --primary: #76c3c1;
  --secondary: #474c68;
  --active-link: #0082f3;
  --active-tab: #8abaf1;
  --active-tab-border: #e6781f;
  --white: #ffffff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--secondary);
  /* min-width: fit-content; */
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.primary.button {
  background-color: var(--primary) !important;
}

/* 0   - 600px:    Phone
  600 - 900px:    Tablet portrait
  900 - 1200px:   Tablet landscape
  [1200 - 1800px] is where our normal styles apply
  1800px +    :   Big Desktop */

@media (max-width: 991px) {
    /* .mobile-bar {
        display: none;
    }     */
}

@media (min-width: 900px) {
    .mobile-bar {
        display: none;
    }

    .body {
      height: 100vh;
    }
}