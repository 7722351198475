.apply {
  height: auto;
  background-image: linear-gradient(352deg, rgba(0, 0, 0, 0), rgba(192, 220, 255, .5)), linear-gradient(94deg, #c9c9c9, #fff);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-top: 10vh;
  padding-left: 95px;
  padding-right: 95px;
  display: flex;
}

.apply .apply__left {
  width: 33%;
  height: 100%;
  background-image: none;
  background-repeat: repeat;
  background-size: auto;
  flex-direction: column;
  flex: 0 auto;
  justify-content: flex-start;
  align-self: auto;
  align-items: flex-start;
  padding-left: 0;
  display: flex;
}

.apply .apply__right {
  width: 58%;
  height: 100%;
  flex: 0 auto;
  padding: 29px;
}

.apply__left h1 {
  color: #474c68 !important;
  font-size: 43px !important;
}

.apply__left p {
  font-size: 30px;
}

.apply__btn {
  display: flex;
  justify-content: flex-end;
  /* width: 100vw; */
}

.apply__btn button {
  width: 7vw;
}

/* 0   - 600px:    Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800px] is where our normal styles apply
1800px +    :   Big Desktop */


@media (max-width: 900px) {
  .apply {
    padding: 100px 0 80px 0;
    flex-direction: column;
  }

  .apply .apply__left {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .apply .apply__right {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .apply__left h1 {
    font-size: 30px !important;
  }

  .apply__left p {
    font-size: 25px !important;
    padding: 0 15px;
  }

  .apply .apply__right .form {
    width: 100%;
  }

  .apply__btn button {
    width: 30vw;
  }
}
