.ui.grid>[class*='four wide'].column {
  width: 30% !important;
}

.ui.grid>[class*='twelve wide'].column {
  width: 70% !important;
}

.product__top {
  height: 52vh;
  /* height: calc(100vh / 2); */
  background-position: 100% 50%, 0px 0px, 0px 0px;
  background-size: 960px, auto, auto;
  background-repeat: no-repeat, repeat, repeat;
}

.product__top h1 {
  height: calc(100vh / 2) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 43px !important;
  color: var(--secondary) !important;
}

.product__bottom {
  height: 51vh;
}

.product__bottom .bms-section {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: row;
  height: 44vh;
}

.product__bottom .bms-section .bms-item {
  width: 24%;
  height: 100%;
}

.product__bottom .bms-section .bms-item .title {
  height: 12%;
  width: 100%;
  font-size: 32px !important;
  color: var(--secondary) !important;
  border-bottom: 2px solid var(--secondary) !important;
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  font-weight: 600;
}

.product__bottom .bms-section .bms-item .sub-title {
  height: 30%;
  font-size: 28px !important;
  color: var(--secondary) !important;
  margin-top: 0px !important;
  margin-bottom: 20px !important;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product__bottom .bms-section .bms-item .specification {
  height: 30%;
}

.product__bottom .bms-section .bms-item .description {
  height: 65%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.product__bottom .four {
  padding-right: 0 !important;
}

.product__bottom .four .vertical.tabular.menu .item {
  background-color: var(--secondary) !important;
  color: var(--white) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase !important;
  font-size: 16px !important;
  border-radius: 0 !important;
  margin-bottom: 5px !important;
  border-right: 5px solid transparent !important;
  height: 7vh;
}

.product__bottom .four .vertical.tabular.menu .item:first-child {
  border-radius: 0 !important;
}

.product__bottom .four .vertical.tabular.menu .item:last-child {
  border-radius: 0 !important;
}

.product__bottom .four .vertical.tabular.menu .item.active {
  background-color: var(--active-tab) !important;
  border-radius: 0 !important;
  transform: translateX(20px) !important;
  z-index: 1;
  height: 10vh;
}

.product__bottom .four .vertical.tabular.menu {
  margin-top: 10px !important;
}

.product__bottom .twelve {
  padding-left: 0 !important;
  margin-top: -50px !important;
}

.product__bottom .twelve .ui.bottom.attached.segment {
  padding: 20px 25px !important;
  background-image: linear-gradient(355deg,
      transparent,
      rgba(192, 220, 255, 0.5)),
    linear-gradient(94deg, #c9c9c9, #fff) !important;
}

.product__bottom h1 {
  font-size: 21px !important;
  padding-left: 2em;
}

.product__bottom {
  margin-top: -75px;
  padding: 0 2em;
}


.product__bottom .tab { 
  height: 51vh;
}

.product .product-description {
  height: 100vh;
  width: 100vw;
  background-image: url("../../images/bms-description.png"), linear-gradient(#e5e7e7, #e5e7e7);
  background-repeat: no-repeat;
  background-size: contain;
}

.product .battery-swapping {
  height: 100vh;
  width: 100vw;
  background-image: url("../../images/battery-swapping.jpg");
  background-repeat: no-repeat;
  background-size: contain;
}

.product .beta-schematic {
  height: 100vh;
  width: 100vw;
  background-image: url("../../images/beta-schematic.jpg");
  background-repeat: no-repeat;
  background-size: contain;
}

.tab h2 {
  font-size: 32px !important;
  color: var(--secondary) !important;
  border-bottom: 2px solid var(--secondary) !important;
  margin-top: 0px !important;
  margin-bottom: 10px !important;
}

.tab h3 {
  font-size: 28px !important;
  color: var(--secondary) !important;
  margin-top: 0px !important;
  margin-bottom: 20px !important;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.tab .tab__flex {
  display: flex;
  flex-basis: 100%;
}

.tab .tab__flex>* {
  flex-basis: 50%;
}

.tab .tab__flex .tab__flex__left h4 {
  color: var(--secondary) !important;
  margin-bottom: 20px !important;
  font-size: 16px !important;
}

.tab__flex__right {
  display: flex;
  justify-content: flex-end;
  position: relative;
  animation: product-img 1s forwards;
}

@keyframes product-img {
  0% {
    right: 0px;
  }

  100% {
    right: 100px;
  }
}

.tab__flex__images img {
  flex-direction: column;
  width: 100px;
  padding: 5px;
  object-fit: cover;
}

.tab__btn__flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
}

@media (max-width: 900px) {
  .product__top {
    height: 25vh;
  }

  .product__top h1 {
    font-size: 30px!important;
    height: 25vh!important;
  }

  .product__bottom {
    height: auto;
    margin-top: 0;
    width: auto!important;
    padding: 0!important;
    /* overflow-x: scroll; */
  }

  .product__bottom .ui.grid {
    flex-direction: column;
    width: auto!important;
  }

  .ui.grid>[class*='four wide'].column {
    width: 100% !important;
  }

  .ui.grid>[class*='twelve wide'].column {
    width: 100%!important;
  }

  .product__bottom .twelve .ui.bottom.attached.segment {
    width: auto!important;
    padding: 0!important;
    overflow: hidden;
  }

  .product__bottom .four .vertical.tabular.menu {
    display: flex;
    flex-direction: row!important;
    overflow-y: auto;
    width: 100vw!important;
    /* scrollbar-width: none!important; */
  }

  .bms-section {
    /* flex-direction: column!important; */
    /* overflow-x: scroll; */
    width: 400vw!important;
    background-image: linear-gradient(355deg, transparent, rgba(192, 220, 255, 0.5)), linear-gradient(94deg, #c9c9c9, #fff) !important;
    /* margin-top: -2vh; */
    /* margin-bottom: -2vh; */
    margin-left: 3vh;
    height: 51vh!important;
    overflow: hidden;
  }

  .bms-item {
    width: 80vw!important;
    padding-top: 1vh;
    /* margin-top: 2vh; */
  }

  .tab {
    height: auto!important;
    overflow-x: scroll;
    min-height: 50vh!important;
    overflow-y: hidden;
    padding-bottom: 2vh;
    padding-left: 1vh;
    padding-right: 1vh;
    padding-top: 2vh;
    scrollbar-width: 0;
  }

  .tab .ui.large.header {
    margin-left: 4vh!important;
  }

  .tab .tab__flex {
    margin-left: 4vh!important;
    height: 40vh!important;
  }

  .product .product-description {
    height: 220px!important;
  }

  .product .battery-swapping {
    height: 220px!important;
  }

  .product .beta-schematic {
    height: 220px!important;
  }
}