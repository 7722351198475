.hero {
    width: 100%;
    height: 101vh;
    background-image: linear-gradient(352deg,
            transparent,
            rgba(192, 220, 255, 0.5)),
        linear-gradient(94deg, #c9c9c9, #fff);
}

.hero .hero__flex {
    display: flex;
    align-items: center;
    height: 100%;
}

.hero .hero__flex .hero__left {
    width: 39%;
    height: 100%;
    object-fit: fill;
    flex-direction: column;
    justify-content: center;
    align-self: auto;
    align-items: flex-start;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 111px;
    padding-left: 60px;
    padding-right: 0;
    display: flex;
}

.hero .hero__flex .hero__right {
    z-index: 2;
    width: 67%;
    height: 100%;
    clear: none;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 145% 109%!important;
    flex: 0 auto;
    order: 0;
    align-self: center;
    margin-left: 0;
    margin-right: 0;
    position: static;
    overflow: visible;
    height: calc(100vh - 100px);
    background: no-repeat center / cover;
    position: relative;
    animation: hero-img-animation-one 1s forwards,
        hero-img-animation-two 3s alternate infinite;
}

.hero__left .header {
    color: var(--secondary) !important;
}

.hero__left p {
    color: var(--secondary) !important;
    font-size: 16px;
}

/* .hero__flex>.hero__left {
    flex-basis: 40%;
    z-index: 1;
} */

.hero__left h1 {
    font-size: 43px !important;
}

.hero .hero__flex .hero__left .hero_heading,
.hero_paragraph,
.hero_button {
    display: flex;
    position: relative;
    animation: heading 1s forwards;
}

@keyframes heading {
    0% {
        left: -100px;
    }

    100% {
        left: 20px;
    }
}

.hero__flex>.hero__right {
    flex-basis: 60%;
}

@keyframes hero-img-animation-one {
    0% {
        bottom: -100px;
    }

    100% {
        bottom: 20px;
    }
}

@keyframes hero-img-animation-two {
    0% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(30deg);
    }
}

.second-section {
    height: 62vh;
    width: 100vw;
    background-image: linear-gradient(352deg,
            transparent,
            rgba(192, 220, 255, 0.5)),
        linear-gradient(94deg, #c9c9c9, #fff);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
}

/* .second-section__flex {
    display: flex;
    align-items: center;
    gap: 40px;
} */

/* .second-section__flex>* {
    flex: 1;
} */

.second-section__left {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 35px;
}

.second-section__right {
    width: 50%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 44px;
    display: flex;
}

.second-section .second-section__left .image{
    width: 100%;
    height: 75%;
    background-position: 42% 32%, 0 0;
    background-repeat: no-repeat, repeat;
    background-size: 152%, auto;
    border-radius: 50%;
    margin-top: 0;
    margin-bottom: -33px;
    overflow: visible;
}

.second-section__right .title {
    font-size: 43px !important;
    color: var(--secondary) !important;
}

.second-section__right .description1 {
    color: #474c68;
    margin-bottom: 14px;
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-weight: 500;
}

.second-section__right p {
    color: var(--secondary) !important;
}


/* 0   - 600px:    Phone
  600 - 900px:    Tablet portrait
  900 - 1200px:   Tablet landscape
  [1200 - 1800px] is where our normal styles apply
  1800px +    :   Big Desktop */

/* 
@media (max-width:600px) {
    .second-section__flex {
        flex-direction: column;
    }

    .second-section__left div {
        width: 200px;
        height: 200px;
        background-size: 100% auto;
        background-position: 100% 0%, 0px 0px;

    }

    .second-section__right h2 {
        font-size: 2em !important;
    }
} */

.feature {
    height: 67vh;
    background-image: radial-gradient(circle closest-side, #fcf3f3, rgba(139, 139, 139, .26)), linear-gradient(rgba(255, 255, 255, .91), rgba(255, 255, 255, .91)), url("../../images/Optimum P_Logo-02.svg");
    background-position: 0 0, 0 0, 100% 0%;
    background-repeat: repeat, repeat, no-repeat;
    /* background-size: 30%; */
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
}

.feature h1 {
    margin-top: 20px;
    font-size: 38px;
    line-height: 44px;
}

.feature__heading {
    color: var(--secondary) !important;
    text-align: center;
    margin-bottom: 58px;
    font-family: Montserrat, sans-serif;
}

.feature__flex {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    flex-basis: 100%;
    margin-bottom: 30px;
    padding: 0 10px;
}

.feature__item {
    flex-basis: calc(25% - 30px);
}

.feature__item-heading {
    display: flex;
    gap: 15px;
    /* margin-bottom: 15px !important; */
    align-items: center;
}

.feature__item-heading h3 {
    color: var(--secondary) !important;
    font-size: 20px !important;
    margin-top: 0 !important;
}

.feature__item-desc p {
    font-size: 16px !important;
    color: #474c68 !important;
}

.feature__item-image {
    height: 75px;
}

/* 0   - 600px:    Phone
  600 - 900px:    Tablet portrait
  900 - 1200px:   Tablet landscape
  [1200 - 1800px] is where our normal styles apply
  1800px +    :   Big Desktop */


/* @media (max-width:600px) {

    .feature__flex {
        flex-direction: column;

    }

    .feature__item {
        flex-basis: calc(25% - 30px);
    }

}

@media (max-width:1000px) {
    .feature__item {
        flex-basis: calc(50% - 30px);
    }
} */

/* 0   - 600px:    Phone
  600 - 900px:    Tablet portrait
  900 - 1200px:   Tablet landscape
  [1200 - 1800px] is where our normal styles apply
  1800px +    :   Big Desktop */

@media (max-width: 900px) {
    .hero__right {
        display: none;
    }

    .hero__left {
        background-image: url("../../images/hero-bg.png");
        width: 100%!important;
        background-position: 50%;
        background-size: cover;
        padding: 0 10px!important;
    }

    .hero__left h1 {
        font-size: 33px!important;
        font-weight: 500!important;
    }

    .hero__left p {
        padding: 0 10px!important;
        width: 90%!important;
    }

    .hero__left .hero_button {
        width: 90%!important;
    }

    .hero__left .hero_button button {
        width: 100%!important;
    }

    .second-section {
        flex-direction: column!important;
        height: 86vh;
    }

    .second-section .second-section__left {
        width: 100%;
        height: 35%;
        padding: 5px 35px!important;
    }

    .second-section .second-section__left .image {
        height: 100%;
        background-position: 50% 34%, 0 0;
        margin-bottom: 0;
    }

    .second-section .second-section__right {
        width: 100%;
        height: 65%;
        padding: 10px!important;
        justify-content: flex-start;
    }

    .second-section__right .title {
        font-size: 25px!important;
    }
    .second-section__right .description-button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .second-section__right .description-button button {
        width: 90%;
    }

    .feature {
        height: auto;
        background-position: 0 0, 0 0, 0% 100%;
    }

    .feature .feature__item {
        width: 100%;
        display: flex!important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-basis: auto;
    }
}

@media (min-width: 900px) {
}

