.about-title {
  height: 35vh;
  background-image: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.about-title h1 {
  font-size: 46px!important;
}

.about {
  background-image: linear-gradient(94deg, #c9c9c9, #fff);
  min-height: 100vh;
}

.about-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55vh;
  width: 100%;
  padding: 0 25px;
}

.about-item .lottie {
  height: 55vh!important;
}

.about-item .lottie-section {
  width: 50%;
  height: 100%;
}

.about-item .text-section {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
} 

.about-item .why-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.about-item .left {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding-right: 25px;
  height: 35vh;
}

.about-item .right {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding-left: 25px;
  height: 35vh;
}

.about-item .item-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 38px;
  font-weight: 600;
}

.about-item h1 {
  text-align: center !important;
  color: var(--secondary) !important;
  font-size: 43px !important;
  margin: 0;
  margin-bottom: 80px !important;
  display: flex;
  gap: 30px;
  margin-bottom: 50px;
}

.about-item ul li {
  font-size: 16px !important;
  margin-bottom: 5px !important;
}
.about-item > * {
  flex: 1;
}

.about-item img {
  width: 100%;
  display: block;
  border-radius: 100%;
}

.about-item h2 {
  color: var(--secondary) !important;
  font-size: 38px !important;
  margin-bottom: 15px !important;
}
.about-item p {
  font-size: 16px !important;
}

.about__top__right div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about__middle__flex {
  display: flex;
  margin-bottom: 50px;
  gap: 30px;
}
.about__middle__flex > * {
  flex: 1;
}

.about__middle__flex h2 {
  color: var(--secondary) !important;
  font-size: 38px !important;
  margin-bottom: 15px !important;
  text-align: center;
}
.about__middle__flex p {
  font-size: 16px !important;
}



/* 0   - 600px:    Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800px] is where our normal styles apply
1800px +    :   Big Desktop */


@media (max-width: 900px) {

  .about-title {
    height: 25vh;
  }

  .about-item {
    flex-direction: column;
    width: 100%;
    height: auto;
    padding-top: 5vh;
  }

  .about-item:first-child {
    padding-top: 5px;
  }

  .about-item .lottie-section {
    width: 100%;
    /* height: 50%; */
  }

  .about-item .lottie {
    height: auto!important;
  }

  .about-item .text-section {
    width: 100%;
    /* height: 50%; */
  }

  .about-item:last-child {
    margin-bottom: 5vh;
  }
}