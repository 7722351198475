.footer {
    height: 38vh;
    background-image: linear-gradient(352deg, rgba(0, 0, 0, 0), rgba(192, 220, 255, .5)), linear-gradient(94deg, #c9c9c9, #fff);
    flex-direction: row;
    justify-content: center;
    margin: 15px;
    display: flex;
}

.footer__top {
    padding: 30px 30px 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer__flex {
    display: flex;
    height: 100%;
    gap: 30px;
}

.footer__item {
    flex-basis: calc(25% - 30px);
}

.footer__item img {
    /* display: block; */
    margin-bottom: 8px;
}

.footer__pvt {
    margin-bottom: 10px;
}

.footer__item h4 {
    font-size: 18px !important;
    color: var(--secondary) !important;
}

.footer-flex {
    display: flex;

    flex-direction: column;
}

.footer-flex>* {
    margin-bottom: 20px !important;
}

.footer__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
}

/* 0   - 600px:    Phone
  600 - 900px:    Tablet portrait
  900 - 1200px:   Tablet landscape
  [1200 - 1800px] is where our normal styles apply
  1800px +    :   Big Desktop */

@media (max-width: 900px) {
    .footer {
        height: auto;
    }
    .footer__top {
        flex-direction: column;
    }
    
    .footer__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px 0;
    }

    .footer__pvt {
        font-size: 20px;
        font-weight: 500;
    }

    .footer__item .footer-menu {
        margin: 0;
    }

    .footer__item .footer-menu-item {
        text-align: center;
    }
}